import Feature from 'ol/Feature.js';
import Map from 'ol/Map';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import Overlay from 'ol/Overlay.js';
import Point from 'ol/geom/Point.js';
import View from 'ol/View.js';
import {
    Circle as CircleStyle,
    Fill,
    Icon,
    Stroke,
    Style,
    Text
} from 'ol/style.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
// import XYZ from 'ol/source/XYZ';


import { useGeographic } from 'ol/proj.js';

useGeographic();


let wp_path = typeof themeinfos !== 'undefined' ? themeinfos.theme_directory : '';

const outerCircleFill = new Fill({
    color: 'rgba(255, 153, 102, 0.3)',
});
const innerCircleFill = new Fill({
    color: 'rgba(255, 165, 0, 0.7)',
});
const textFill = new Fill({
    color: '#fff',
});
const textStroke = new Stroke({
    color: 'rgba(0, 0, 0, 0.6)',
    width: 3,
});
const innerCircle = new CircleStyle({
    radius: 14,
    fill: innerCircleFill,
});
const outerCircle = new CircleStyle({
    radius: 20,
    fill: outerCircleFill,
});

export const mapsHandler = {
    init: async function () {

        let mapContainer = document.getElementById('map');
        if (!mapContainer) return;

        // mapContainer.addEventListener('click', () => {
        //     let headerHeight = document.getElementById('header-main').getBoundingClientRect().height;
        //     let offset = mapContainer.getBoundingClientRect().top + window.scrollY - headerHeight;
        //     window.scrollTo({
        //         'top': offset,
        //         behavior: 'smooth'
        //     });
        // });

        const rasterLayer = new TileLayer({
            source: new OSM({

            }),
        });

        const map = new Map({
            target: 'map',
            layers: [rasterLayer],
            view: new View({
                // center: [43.270007399280196, 5.395901497623401],
                center: [2.433108473315154, 46.611453796874436],
                zoom: 5.75,
            }),
        });

        if (!map) return;

        // Get Json
        let jsonPath = map.targetElement_.dataset.jsonPath;
        let showCluster = map.targetElement_.hasAttribute('data-show-cluster') ? true : false;
        // console.log(map.targetElement_.dataset.showCluster, jsonPath, showCluster);
        let points = await mapsHandler.fetchPointsJSON(jsonPath);
        // Prepare points
        // console.log(points);

        if (showCluster) {
            let clustersLayer = mapsHandler.setClustersLayer(points);
            map.addLayer(clustersLayer);
        }

        // Add layer
        // console.log(vectorLayer);
        // let pointsLayer = mapsHandler.setPointsLayer(points, map, showCluster);
        let pointsLayer = mapsHandler.setPointsLayer(points, map, false);
        map.addLayer(pointsLayer);

        // Popup
        const element = document.getElementById('popup');
        // const container = document.getElementById('popup');
        const content = document.getElementById('popup-content');
        const closer = document.getElementById('popup-closer');


        const popup = new Overlay({
            element: element,
            positioning: 'bottom-center',
            stopEvent: false,
            autoPan: {
                animation: {
                    duration: 150,
                },
                margin: 40
            }
        });
        map.addOverlay(popup);

        // display popup on click
        map.on('click', function (evt) {

            // Scroll Viewport to Map
            let headerHeight = document.getElementById('header-main').getBoundingClientRect().height;
            let offset = mapContainer.getBoundingClientRect().top + window.scrollY - headerHeight;
            var top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
            if (top > offset) {
                window.scrollTo({
                    'top': offset,
                    behavior: 'smooth'
                });
            }

            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });

            if (!feature) {
                return;
            }

            let pointTitle = feature.get('title');
            let pointContent = feature.get('content');
            let pointInfos = feature.get('infos');
            let pointContacts = feature.get('contacts');
            let pointAddress = feature.get('address');
            let pointAddress_multiline = feature.get('address_multiline');
            let pointPhone = feature.get('phone');
            let pointEmail = feature.get('email');
            let pointLink = feature.get('link');
            let pointType = feature.get('type');

            element.style.display = "";

            // Populate popup data
            if (pointTitle) {
                content.innerHTML = '<div class="title-3 text-black mb-4">' + pointTitle + '</div>';
            }
            if (pointContacts) {

                let contacts = "";
                pointContacts.forEach((contact) => {
                    contacts += '<p>';
                    contacts += contact['position'] + ' : ';
                    contacts += contact['firstname'] + ' ';
                    contacts += contact['lastname'];
                    contacts += '</p>';

                });
                content.innerHTML += '<div class="popup-contacts border-b border-b-divider mb-4 pb-4">' + contacts + '</div>';

                if (pointContent) {
                    content.innerHTML += '<div class="popup-content pt-4">' + pointContent + '</div>';
                }

            } else {
                if (pointContent) {
                    content.innerHTML += '<div class="popup-content border-b border-b-divider mb-4 pb-4">' + pointContent + '</div>';
                }
            }
            if (pointAddress_multiline) {
                let address_multiline = "";
                address_multiline += '<p>';
                address_multiline += pointAddress_multiline['street'] + '<br>';
                address_multiline += pointAddress_multiline['postcode'] + ', ';
                address_multiline += pointAddress_multiline['city'];
                address_multiline += '</p>';
                content.innerHTML += '<div class="popup-address">' + address_multiline + '</div>';
            } else if (pointAddress) {
                content.innerHTML += '<div class="popup-address"><p>' + pointAddress + '</p></div>';
            }

            if (pointInfos) {
                let infos = "";
                pointInfos.forEach((info) => {
                    infos += '<p>';
                    infos += '<span class="capitalize">' + info['type'] + '</span> : ';
                    infos += info['info'];
                    infos += '</p>';

                });
                content.innerHTML += '<div class="popup-infos">' + infos + '</div>';
            }

            if (pointPhone) {
                content.innerHTML += '<p class="popup-phone">' + pointPhone + '</p>';
            }
            if (pointEmail) {
                content.innerHTML += '<p class="popup-email">' + pointEmail + '</p>';
            }
            if (pointLink) {
                pointType = pointType ? 'bg-' + pointType : '';
                content.innerHTML += '<a class="btn-primary ' + pointType + ' mt-12" href="' + pointLink + '" target="_blank" rel="noopener">Voir les informations</a>';
            }

            popup.setPosition(evt.coordinate);
        });

        closer.onclick = function () {
            popup.setPosition(undefined);
            closer.blur();
            return false;
        };

    },

    fetchPoints: () => {

    },

    fetchPointsJSON: (jsonPath) => {

        // let points;
        // console.log(jsonPath);

        return fetch(jsonPath)
            .then(response => response.json())
            .then(points => points)

        // fetch(jsonPath)
        //     .then(function (response) {
        //         // The API call was successful!
        //         // console.log('success!', response);
        //         // The API call was successful!
        //         if (response.ok) {
        //             return response.json();
        //         } else {
        //             return Promise.reject(response);
        //         }
        //     }).then(function (data) {
        //         // This is the JSON from our response
        //         // points = data;
        //         // console.log(data);
        //         points = data;
        //     }).catch(function (err) {
        //         // There was an error
        //         console.warn('Something went wrong.', err);
        //     });
        // // console.log(points);
        // return points;
        // // return data;
    },

    setPointsLayer: (points, map, showClusters = true) => {

        // let points = JSON.parse(data);
        // let vectorLayer = new VectorLayer();
        let points_arr = [];

        // console.log('data2: ' + points);

        points.forEach(function (point, index) {
            // console.log(point, point.lat, point.lng);

            let iconFeature = new Feature({
                geometry: new Point([point.lng, point.lat]),
                title: point.name,
                content: point.content,
                contacts: point.contacts,
                infos: point.infos,
                address: point.address,
                address_multiline: point.address_infos,
                phone: point.phone,
                email: point.email,
                link: point.link,
                type: point.type
            });

            let iconStyle = new Style({
                image: new Icon({
                    // anchorOrigin: 'bottom-left',
                    // anchor: [0, -50],
                    // anchorXUnits: 'pixels',
                    // anchorYUnits: 'fraction',
                    displacement: [0, 17],
                    // color: 'rgb(var(--color-primary))',
                    // color: '#DD6031',
                    src: wp_path + '/images/svg/' + point.icon + '.svg',
                }),
            });

            iconFeature.setStyle(iconStyle);

            points_arr.push(iconFeature);

        })

        // console.log(points_arr);
        let vectorSource = new VectorSource({
            // features: [iconFeature],
            features: points_arr,
        });

        // let source = vectorSource;

        // if (showClusters === true) {

        // const clusterSource = new Cluster({
        //     distance: 40,
        //     minDistance: 20,
        //     source: vectorSource,
        // });

        // source = clusterSource;
        // mapsHandler.setClustersLayer(points, map)
        // }

        let vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        // Return Layer
        return vectorLayer;
    },

    setClustersLayer: (points, map) => {
        let points_arr = [];

        // console.log('data2: ' + points);

        points.forEach(function (point, index) {
            // console.log(point, point.lat, point.lng);

            let iconFeature = new Feature({
                geometry: new Point([point.lat, point.lng]),
                name: point.name,
                content: point.content
            });

            let iconStyle = new Style({
                image: new Icon({
                    anchor: [-0.5, -0.5],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'images/svg/icons/icon-' + point.icon + '.svg',
                }),
            });

            iconFeature.setStyle(iconStyle);

            points_arr.push(iconFeature);

        })

        // console.log(points_arr);
        let vectorSource = new VectorSource({
            // features: [iconFeature],
            features: points_arr,
        });

        // console.log(points_arr);
        const clusterSource = new Cluster({
            distance: 40,
            minDistance: 20,
            source: vectorSource
        });

        let clustersLayer = new VectorLayer({
            source: clusterSource,
            style: clusterStyle,
        });


        // map.addLayer(clusterSource);
        // Return Layer
        return clustersLayer;

        function clusterStyle(feature) {
            const size = feature.get('features').length;
            if (size > 1) {
                return [
                    new Style({
                        image: outerCircle,
                    }),
                    new Style({
                        image: innerCircle,
                        text: new Text({
                            text: size.toString(),
                            fill: textFill,
                            stroke: textStroke,
                        }),
                    }),
                ];
            }
            // const originalFeature = feature.get('features')[0];
            // return clusterMemberStyle(originalFeature);
        }

    }
}